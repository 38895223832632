import { EuiAvatar, EuiFlexGroup } from '@elastic/eui';
import { useContext } from 'react';
import { ResultsContext, ResultsDispatchContext } from '../appReducer';
 
export default function SavedResultBar() {
  const results = useContext(ResultsContext);
  const saveResultDispatcher = useContext(ResultsDispatchContext);
  return <EuiFlexGroup direction="column" gutterSize="xs">
  {results?.map((r, i) => <EuiAvatar color={r.color} type="space" name={r.reqestState.searchTerm || `Result ${i + 1}`} onClick={() => saveResultDispatcher?.({
    type: 'select',
    idx: r.responseState.requestId
  })} iconType={r.isShown ? 'pinFilled' : 'pin'} key={r.responseState.requestId} />)}
</EuiFlexGroup>
}