import AppSearchAPIConnector from "@elastic/search-ui-app-search-connector";
import { useEffect, useMemo, useReducer, useState } from "react";
import {
  ErrorBoundary,
  SearchProvider,
  SearchBox,
  Results,
  WithSearch,
  Paging,
  PagingInfo,
} from "@elastic/react-search-ui";
import { EuiProvider, EuiPanel, EuiFlexGroup, EuiFlexItem, EuiButtonIcon, EuiCard,
  EuiButton, EuiPageSection, EuiSpacer, EuiPageHeader } from '@elastic/eui';
import '@elastic/eui/dist/eui_theme_light.css';
import { SearchDriverOptions } from "@elastic/search-ui";
import MyMapResults from "./components/MyMapResults";
import MyMapResult from "./components/MyMapResult";
import MyResult from "./components/MyResult";
import MyResults from "./components/MyResults";
import MySearchBox from "./components/MySearchBox";
import MyPaging from "./components/MyPaging";
import MySavedResultFlyout from "./components/MySavedResultFlyout";
import MySavedResult from "./components/MySavedResult";
import SavedResultBar from "./components/SavedResultBar";
import MySavedResults from "./components/MySavedResults";
import { ResultsContext, ResultsDispatchContext, resultsReducer } from './appReducer';
import MyMapSavedResults from "./components/MyMapSavedResults";

const connector = new AppSearchAPIConnector({
  searchKey: "search-xvqho7yj1nw6eqpuk1kt2nzy",
  engineName: "search-bma",
  endpointBase: "http://bma-search.bangkok.go.th"
});

const now = new Date();
const fromToday = new Date();
fromToday.setHours(0,0,0,0);
const fromMonday = new Date();
if (fromMonday.getDay() !== 1) {
  fromMonday.setHours(-24 * (fromMonday.getDay() - 1));
}
const fromMonth = new Date(now.getFullYear(), now.getMonth(), 1);

const config: SearchDriverOptions = {
  alwaysSearchOnInitialLoad: false,
  apiConnector: connector,
  hasA11yNotifications: true,
  searchQuery: {
    result_fields: {
      title: { raw: {}, snippet: { fallback: true } },
      district: { raw: {} },
      subdistrict: { raw: {} },
      body: { snippet: {size: 128}, raw: {} },
      location: { raw: {} },
      source: { raw: {} },
      retrived_at: { raw: {} },
      created_at: { raw: {} },
      updated_at: { raw: {} },
    },
    search_fields: {
      title: {
        weight: 5
      },
      body: {},
    },
    disjunctiveFacets: ["source"],
    facets: {
      district: { type: "value", size: 30 },
      subdistrict: { type: "value", size: 30 },
      source: { type: "value", size: 30 },
      created_at: {
        type: "range",
        ranges: [
          {
            name: "Today",
            from: fromToday.toISOString(),
          },
          {
            name: "This week",
            from: fromMonday.toISOString(),
          },
          {
            name: "This month",
            from: fromMonth.toISOString(),
          }
        ]
      }
    },
    resultsPerPage: 100
  },
  initialState: {
    resultsPerPage: 100
  }
};

export default function App() {
  const [resultState, dispatch] = useReducer(resultsReducer, []);
  const [isShowResult, setIsShowResult] = useState(true);
  const favQueries = useMemo(() => [
    [
      {
        headerImage: 'https://source.unsplash.com/400x200/?road-safety',
        id: 'road-safety',
        title: 'ความปลอดภัยบนถนน',
        description: 'ความปลอดภัยบนถนน ที่ได้รับแจ้งจากประชาชนผ่าน Traffy Fondue ในสัปดาห์นี้ ',
        resultLink: `http://bma-search.bangkok.go.th/?q=%E0%B8%84%E0%B8%A7%E0%B8%B2%E0%B8%A1%E0%B8%9B%E0%B8%A5%E0%B8%AD%E0%B8%94%E0%B8%A0%E0%B8%B1%E0%B8%A2%E0%B8%9A%E0%B8%99%E0%B8%96%E0%B8%99%E0%B8%99&size=n_100_n&filters%5B0%5D%5Bfield%5D=location&filters%5B0%5D%5Bvalues%5D%5B0%5D%5Bcenter%5D=13.7525%2C100.494167&filters%5B0%5D%5Bvalues%5D%5B0%5D%5Bdistance%5D=n_13883.181360312274_n&filters%5B0%5D%5Bvalues%5D%5B0%5D%5Bunit%5D=m&filters%5B0%5D%5Btype%5D=all&filters%5B1%5D%5Bfield%5D=created_at&filters%5B1%5D%5Bvalues%5D%5B0%5D%5Bfrom%5D=${encodeURIComponent(fromMonday.toISOString())}&filters%5B1%5D%5Btype%5D=all`,
        query: {
          "query": "ความปลอดภัยบนถนน",
          "search_fields": {
            "title": {
              "weight": 5
            },
            "body": {}
          },
          "filters": {
            "all": [
              {
                "all": [
                  {
                    "created_at": {
                      from: fromMonday.toISOString(),
                    }
                  }
                ]
              }
            ]
          },
        }
      },
      {
        headerImage: 'https://source.unsplash.com/400x200/?road-lane',
        id: 'road-lane',
        title: 'ซอยเปลี่ยว มียาเสพติด',
        description: 'ซอยเปลี่ยว มียาเสพติด ค้นหาจากแหล่งข้อมูล Bangkok Risk Map และ ที่ได้รับแจ้งจากประชาชนผ่าน Traffy Fondue',
        resultLink: 'http://bma-search.bangkok.go.th/?q=ซอยเปลี่ยว%20มียาเสพติด&size=n_100_n&filters%5B0%5D%5Bfield%5D=source&filters%5B0%5D%5Bvalues%5D%5B0%5D=Traffy%20fondue&filters%5B0%5D%5Bvalues%5D%5B1%5D=BMA%20Risk%20Map%20%3A%20Tedsakit&filters%5B0%5D%5Btype%5D=any&filters%5B1%5D%5Bfield%5D=location&filters%5B1%5D%5Bvalues%5D%5B0%5D%5Bcenter%5D=13.7525%2C100.494167&filters%5B1%5D%5Bvalues%5D%5B0%5D%5Bdistance%5D=n_13883.181360312274_n&filters%5B1%5D%5Bvalues%5D%5B0%5D%5Bunit%5D=m&filters%5B1%5D%5Btype%5D=all',
        query: {
          "query": "ซอยเปลี่ยว มียาเสพติด",
          "filters": {
            "all": [
              {
                "any": [
                  {
                    "source": [
                      "Traffy fondue",
                      "BMA Risk Map : Tedsakit"
                    ]
                  }
                ]
              }
            ]
          },
          "search_fields": {
            "title": {
              "weight": 5
            },
            "body": {}
          }
        }
      },
      {
        headerImage: 'https://source.unsplash.com/400x200/?manhole-covers',
        id: 'manhole-covers',
        title: 'ฝาท่อระบายน้ำ อันตราย',
        description: 'ฝาท่อระบายน้ำ อันตราย',
        resultLink: 'http://bma-search.bangkok.go.th/?q=%E0%B8%9D%E0%B8%B2%E0%B8%97%E0%B9%88%E0%B8%AD%E0%B8%A3%E0%B8%B0%E0%B8%9A%E0%B8%B2%E0%B8%A2%E0%B8%99%E0%B9%89%E0%B8%B3%20%E0%B8%AD%E0%B8%B1%E0%B8%99%E0%B8%95%E0%B8%A3%E0%B8%B2%E0%B8%A2&size=n_100_n&filters%5B0%5D%5Bfield%5D=location&filters%5B0%5D%5Bvalues%5D%5B0%5D%5Bcenter%5D=13.7525%2C100.494167&filters%5B0%5D%5Bvalues%5D%5B0%5D%5Bdistance%5D=n_14009.355730647616_n&filters%5B0%5D%5Bvalues%5D%5B0%5D%5Bunit%5D=m&filters%5B0%5D%5Btype%5D=all',
        query: {
          "query": "ฝาท่อระบายน้ำ อันตราย",
          "search_fields": {
            "title": {
              "weight": 5
            },
            "body": {}
          }
        }
      },
    ],
    [
      {
        headerImage: 'https://source.unsplash.com/400x200/?street-light',
        id: 'street-light',
        title: 'มืด ไฟฟ้าดับ ถนน',
        description: 'ค้นหาจากแหล่งข้อมูลเฉพาะ ในเขตจตุจักร',
        resultLink: 'http://bma-search.bangkok.go.th/?q=มืด%20ไฟฟ้าดับ%20ถนน&size=n_100_n&filters%5B0%5D%5Bfield%5D=district&filters%5B0%5D%5Bvalues%5D%5B0%5D=จตุจักร&filters%5B0%5D%5Btype%5D=all&filters%5B1%5D%5Bfield%5D=location&filters%5B1%5D%5Bvalues%5D%5B0%5D%5Bcenter%5D=13.831210648993295%2C100.55280990243956&filters%5B1%5D%5Bvalues%5D%5B0%5D%5Bdistance%5D=n_13882.236202407934_n&filters%5B1%5D%5Bvalues%5D%5B0%5D%5Bunit%5D=m&filters%5B1%5D%5Btype%5D=all',
        query: {
          "query": "มืด ไฟฟ้าดับ ถนน",
          "search_fields": {
            "title": {
              "weight": 5
            },
            "body": {}
          },
          "filters": {
            "all": [
              {
                "all": [
                  {
                    "district": "จตุจักร"
                  }
                ]
              }
            ]
          },
        }
      },
      {
        headerImage: 'https://source.unsplash.com/400x200/?zebra-crossing',
        id: 'zebra-crossing',
        title: 'ทางม้าลาย',
        description: 'ทางม้าลาย',
        resultLink: 'http://bma-search.bangkok.go.th/?q=%E0%B8%97%E0%B8%B2%E0%B8%87%E0%B8%A1%E0%B9%89%E0%B8%B2%E0%B8%A5%E0%B8%B2%E0%B8%A2&size=n_100_n&filters%5B0%5D%5Bfield%5D=location&filters%5B0%5D%5Bvalues%5D%5B0%5D%5Bcenter%5D=13.7525%2C100.494167&filters%5B0%5D%5Bvalues%5D%5B0%5D%5Bdistance%5D=n_14009.355730647616_n&filters%5B0%5D%5Bvalues%5D%5B0%5D%5Bunit%5D=m&filters%5B0%5D%5Btype%5D=all',
        query: {
          "query": "ทางม้าลาย",
          "search_fields": {
            "title": {
              "weight": 5
            },
            "body": {}
          }
        }
      },
      {
        headerImage: 'https://source.unsplash.com/400x200/?noise-pollution',
        id: 'noise-pollution',
        title: 'เสียงดังรบกวน',
        description: 'เสียงดังรบกวน',
        resultLink: 'http://bma-search.bangkok.go.th/?q=%E0%B9%80%E0%B8%AA%E0%B8%B5%E0%B8%A2%E0%B8%87%E0%B8%94%E0%B8%B1%E0%B8%87%E0%B8%A3%E0%B8%9A%E0%B8%81%E0%B8%A7%E0%B8%99&size=n_100_n&filters%5B0%5D%5Bfield%5D=location&filters%5B0%5D%5Bvalues%5D%5B0%5D%5Bcenter%5D=13.7525%2C100.494167&filters%5B0%5D%5Bvalues%5D%5B0%5D%5Bdistance%5D=n_14009.355730647616_n&filters%5B0%5D%5Bvalues%5D%5B0%5D%5Bunit%5D=m&filters%5B0%5D%5Btype%5D=all',
        query: {
          "query": "เสียงดังรบกวน",
          "search_fields": {
            "title": {
              "weight": 5
            },
            "body": {}
          }
        }
      },
    ]
  ], []);
  const [favResult, setFavResult] = useState<Record<string, number>>({});
  useEffect(() => {
    const queries = favQueries.flat()
    if (Object.keys(favResult).length === 0) {
      Promise.all(queries.map((f) => fetch('http://bma-search.bangkok.go.th/api/as/v1/engines/search-bma/search.json', {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer search-xvqho7yj1nw6eqpuk1kt2nzy"
          },
          body: JSON.stringify(f.query)
      }).then((r) => r.json()))).then((results) => {
        const r:Record<string, number> = {}
        for (let i = 0; i < results.length; i++) {
          if (results[i].meta && results[i].meta.page && queries[i]) {
            r[queries[i].id] = results[i].meta.page.total_results
          }
        }
        setFavResult(r)
      })
    }
  }, [favQueries, favResult]);
  return (
    <EuiProvider colorMode="light">
      <SearchProvider config={config}>
        <WithSearch
          mapContextToProps={({ wasSearched, searchTerm }) => ({
            wasSearched, searchTerm
          })}
        >
          {({ wasSearched, searchTerm }) => {
            return (
              <>
                <EuiPageHeader
                  pageTitle={<a href="/">BMA Search</a>}
                  iconType="bma_logo.svg"
                  bottomBorder
                  paddingSize="m"
                  rightSideItems={[
                    <SearchBox
                      searchAsYouType={true}
                      debounceLength={300}
                      view={MySearchBox}
                    />,
                    <EuiSpacer />
                  ]}
                />
                <EuiPageSection paddingSize="none">
                  <ErrorBoundary>
                    <EuiFlexGroup direction="column">
                      { !wasSearched && <EuiFlexItem>
                        {
                          favQueries.map((f, i) =>
                          <EuiFlexGroup gutterSize="l" key={i} style={{paddingBottom: '12px'}}>
                            <EuiFlexItem></EuiFlexItem>
                            {
                              f.map((r) => <EuiFlexItem key={r.id}>
                                <EuiCard
                                  textAlign="left"
                                  image={
                                    <div>
                                      <img
                                        src={r.headerImage}
                                        alt={r.id}
                                      />
                                    </div>
                                  }
                                  title={`${r.title} (${favResult[r.id]})`}
                                  description={r.description}
                                  footer={
                                    <EuiFlexGroup justifyContent="flexEnd">
                                      <EuiFlexItem grow={false}>
                                        <EuiButton href={r.resultLink}>Go to search result</EuiButton>
                                      </EuiFlexItem>
                                    </EuiFlexGroup>
                                  }
                                />
                              </EuiFlexItem>)
                            }
                            <EuiFlexItem></EuiFlexItem>
                          </EuiFlexGroup>
                          )
                        }
                      </EuiFlexItem> }
                    </EuiFlexGroup>
                    { wasSearched && <ResultsContext.Provider value={resultState}>
                      <ResultsDispatchContext.Provider value={dispatch}>
                        <EuiFlexGroup gutterSize="none">
                          {resultState.length > 0 && <EuiFlexItem grow={false}><EuiPanel paddingSize="s" grow={true}>
                            <SavedResultBar />
                          </EuiPanel></EuiFlexItem>}
                          {resultState?.filter((r) => r.isSelected).length > 0 && <EuiFlexItem grow={false} style={{ width: 450 }}>
                            <MySavedResults />
                          </EuiFlexItem>}
                          <EuiFlexItem grow={true}>
                            { wasSearched && isShowResult && resultState?.filter((r) => r.isSelected).length === 0 ? <Results
                              titleField="title"
                              urlField="nps_link"
                              thumbnailField="image_url"
                              shouldTrackClickThrough
                              view={MyMapResults}
                              resultView={MyMapResult} 
                            /> : <MyMapSavedResults />}
                          </EuiFlexItem>
                          { wasSearched && isShowResult && resultState?.filter((r) => r.isSelected).length === 0 && <EuiFlexItem grow={false}  style={{ width: 450, height: 400}}>
                            <EuiFlexGroup direction="column">
                              <EuiFlexItem>
                                <Results
                                    titleField="title"
                                    urlField="nps_link"
                                    thumbnailField="image_url"
                                    shouldTrackClickThrough
                                    view={MyResults}
                                    resultView={MyResult}
                                  />
                              </EuiFlexItem>
                              <EuiFlexGroup justifyContent="spaceAround">
                                <EuiFlexItem grow={false}>
                                <PagingInfo />
                                <Paging view={MyPaging} />
                                </EuiFlexItem>
                                <EuiFlexItem grow={false}>
                                  <MySavedResult />
                                </EuiFlexItem>
                                <EuiFlexItem grow={false}>
                                <EuiButtonIcon
                                  onClick={() => setIsShowResult(!isShowResult)}
                                  color="danger"
                                  iconType='cross'
                                  aria-label="close"
                                  />
                                </EuiFlexItem>
                              </EuiFlexGroup>
                            </EuiFlexGroup>
                          </EuiFlexItem>}
                          { wasSearched && !isShowResult && <EuiFlexItem grow={false} >
                            <EuiFlexGroup alignItems="center">
                              <EuiFlexItem>
                                <EuiButtonIcon iconType="arrowLeft" aria-label="Show" onClick={() => setIsShowResult(!isShowResult)} />
                              </EuiFlexItem>
                            </EuiFlexGroup>
                          </EuiFlexItem> }
                        </EuiFlexGroup>
                      </ResultsDispatchContext.Provider>
                    </ResultsContext.Provider>}
                    <MySavedResultFlyout />
                  </ErrorBoundary>
                </EuiPageSection>
              </>
            );
          }}
        </WithSearch>
      </SearchProvider>
    </EuiProvider>
  );
}