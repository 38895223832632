import { useState } from "react";
import { EuiFlyout, EuiFlyoutHeader, EuiFlexGroup, EuiFlexItem, EuiImage, EuiTitle,
  EuiFlyoutBody, EuiFlyoutFooter, EuiButton, useGeneratedHtmlId } from "@elastic/eui";
import { WithSearch } from "@elastic/react-search-ui";


export default function MySavedResultFlyout() {
  const [isFlyoutVisible, setIsFlyoutVisible] = useState('');

  const pushedFlyoutTitleId = useGeneratedHtmlId({
    prefix: 'pushedFlyoutTitle',
  });

  const engineImg: Record<string, string> = {
    "BMA Risk Map : Tedsakit": "/immigration.png",
    "BMA Risk Map : PM 2.5": "/air-quality.png",
    "BMA Risk Map : Flood": "/tide.png",
    "BMA Risk Map : Fire": "/fire.png",
    "BMA Risk Map : Accident": "/fender-bender.png",
    "BMA project": "/project.png",
    "Traffy fondue": "/partners.png",
  }

  return (
    <WithSearch mapContextToProps={({  filters, addFilter, removeFilter, setFilter }) => ({  filters, addFilter, removeFilter, setFilter  })}>
      {({ filters, addFilter, removeFilter, setFilter }) => isFlyoutVisible && <EuiFlyout
            size="s"
            side="left"
            onClose={() => setIsFlyoutVisible('')}
            aria-labelledby={pushedFlyoutTitleId}
          >
            <EuiFlyoutHeader hasBorder>
            <EuiFlexGroup>
              <EuiFlexItem grow={false}>
                <EuiImage
                      size={32}
                      alt=""
                      src={engineImg[isFlyoutVisible]}
                    />
              </EuiFlexItem>
              <EuiFlexItem>
                <EuiTitle size="m">
                  <h2 id={pushedFlyoutTitleId}>{isFlyoutVisible}</h2>
                </EuiTitle>
              </EuiFlexItem>
            </EuiFlexGroup>
            </EuiFlyoutHeader>
            <EuiFlyoutBody>
            <EuiFlexGroup direction="column" gutterSize="xs">
              
            </EuiFlexGroup>
            </EuiFlyoutBody>
            <EuiFlyoutFooter>
            <EuiFlexGroup
              gutterSize="s"
              alignItems="center"
              wrap
            >
              <EuiFlexItem grow={false}>
                
              </EuiFlexItem>
              <EuiFlexItem grow={false}>
                <EuiButton onClick={() => setIsFlyoutVisible('')} color="accent">Close</EuiButton>
              </EuiFlexItem>
            </EuiFlexGroup>
            </EuiFlyoutFooter>
          </EuiFlyout>
        
      }
    </WithSearch>
  );
}