import type {
  FieldValue,
} from "@elastic/search-ui";
import { EuiSelectable, EuiTitle, EuiSpacer } from '@elastic/eui';

type FacetViewProps = {
  className?: string;
  label: string;
  onMoreClick: () => void;
  onRemove: (value: FieldValue) => void;
  onChange: (value: FieldValue) => void;
  onSelect: (value: FieldValue) => void;
  options: any[];
  showMore: boolean;
  values: any;
  showSearch: boolean;
  onSearch: (value: string) => void;
  searchPlaceholder: string;
};

function MyDateFacet({
  className,
  label,
  onMoreClick,
  onRemove,
  onSelect,
  options,
  showMore,
  showSearch,
  onSearch,
  searchPlaceholder
} : FacetViewProps) {
  console.log(options)
  return (
    <>
      <EuiTitle size="xxxs">
        <h3>{label}</h3>
      </EuiTitle>
      <EuiSpacer size="xs"  />
      <EuiSelectable
        aria-label={label}
        singleSelection={true}
        options={options.map((option) => {
          return {
            label: `${option.value.name} (${option.count})`,
            checked: option.selected ? 'on' : undefined
          }
        })}
        onChange={(newOptions) => {
          for(let i = 0; i < options.length; i++) {
            if (!options[i].selected && newOptions[i].checked === 'on') {
              onSelect(options[i].value)
            } else if (options[i].selected && !newOptions[i].checked) {
              onRemove(options[i].value)
            }
          }
        }}
        listProps={{ bordered: true }}
      >
        {(list) => list}
      </EuiSelectable>
    </>
  );
}

export default MyDateFacet;