import { EuiFlexGroup, EuiFlexItem, EuiFieldSearch, EuiButton } from "@elastic/eui";
import {
  SearchBoxViewProps,
} from "@elastic/react-search-ui-views";
import MyFilterPopout from "./MyFilterPopout";

export default function MySearchBox({ value, onChange, onSubmit }: SearchBoxViewProps) {
  return (
    <form onSubmit={onSubmit}>
      <EuiFlexGroup style={{minWidth: '50vw'}}>
        <EuiFlexItem>
          <EuiFieldSearch
              placeholder="Search"
              value={value}
              onChange={(e) => onChange(e.target.value)}
              isClearable={true}
              autoFocus={true}
              fullWidth={true}
            />
        </EuiFlexItem>
        <EuiFlexItem grow={false}>
          <EuiButton type="submit" fill={true}>Search</EuiButton>
        </EuiFlexItem>
        <EuiFlexItem grow={false}>
          <MyFilterPopout />
        </EuiFlexItem>
      </EuiFlexGroup>
    </form>
  );
}