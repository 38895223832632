import {
  ResultViewProps,
} from "@elastic/react-search-ui-views";
import { EuiCard, EuiTitle, EuiFlexItem, EuiSplitPanel, EuiFlexGroup, EuiBadge, EuiText } from '@elastic/eui';
import { MapContext } from '../mapContext';
import { useContext } from "react";
import { LatLng } from "leaflet";

function MyResult({
  className,
  result,
  onClickLink,
  titleField,
  urlField,
  thumbnailField,
  ...rest
}: ResultViewProps) {
  const ctx = useContext(MapContext);

  const handlePickPin = () => {
    ctx?.setCenter(new LatLng(result.location.raw[1], result.location.raw[0]))
  }

  const dtFormater = new Intl.DateTimeFormat('en-UK', { dateStyle: 'short', timeStyle: 'short' });

  return (
    <EuiSplitPanel.Inner {...rest} key={result.id.raw} paddingSize="s">
      <EuiCard
        title={
          <EuiFlexGroup>
            <EuiFlexItem>
              <EuiTitle size="xs">
                <span dangerouslySetInnerHTML={{__html: result.title.snippet || (result.title.raw || 'ยังไม่ระบุประเภท')}}></span>
              </EuiTitle>
              <EuiText size="xs">{ result.created_at ? dtFormater.format(new Date(result.created_at.raw)) : '' }</EuiText>
            </EuiFlexItem>
          </EuiFlexGroup>
      }
        description={<span dangerouslySetInnerHTML={{__html: result.body.snippet || (result.body.raw.slice(0, 128) + (result.body.raw.length > 128 ? '...' : '')) }} />}
        onClick={handlePickPin}
        textAlign="left"
        footer={
          <EuiFlexGroup wrap responsive={false} gutterSize="xs">
            <EuiFlexItem grow={false}>
              <EuiBadge color="primary">{(result.source || {}).raw}</EuiBadge>
            </EuiFlexItem>
            <EuiFlexItem grow={false}>
              <EuiBadge>{(result.district || {}).raw}</EuiBadge>
            </EuiFlexItem>
            <EuiFlexItem grow={false}>
              <EuiBadge color="hollow">{(result.subdistrict || {}).raw}</EuiBadge>
            </EuiFlexItem>
          </EuiFlexGroup>
        }
      />
    </EuiSplitPanel.Inner>
  );
}
export default MyResult;