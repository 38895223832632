import type { SearchContextState } from "@elastic/search-ui";
import {
  ResultsViewProps,
} from "@elastic/react-search-ui-views";
import React from "react";

import { EuiSplitPanel } from '@elastic/eui';

export type ResultsContainerContext = Pick<SearchContextState, "results">;

function MyResults({
  children,
  className,
  ...rest
}: ResultsViewProps & React.HTMLAttributes<HTMLUListElement>) {
  return (
    <EuiSplitPanel.Outer hasShadow={false} grow={false} style={{
      overflowY: 'scroll',
      maxBlockSize: 'calc(100vh - 140px)',
    }}>
      {children}
    </EuiSplitPanel.Outer>
  );
}

export default MyResults;