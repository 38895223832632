import { SearchResult } from "@elastic/search-ui";
import { Dispatch, createContext } from 'react';
import { euiPaletteColorBlind } from '@elastic/eui';

type RequestStateType = {
  current: number,
  filters: any,
  resultsPerPage: number,
  searchTerm: string,
  sort: any
}
type ResponseStateType = {
  requestId: string,
  facets: any,
  results: SearchResult[],
  resultSearchTerm: string,
  totalResults: number
}
export type SaveSearchItemType = {
  reqestState: RequestStateType,
  responseState: ResponseStateType,
  isShown: boolean,
  isSelected: boolean,
  color: string,
};
type SaveSearchAction = {
  type: string;
  result?: any;
  idx?: string;
  jdx?: [string, string]
}

const colorPalette = euiPaletteColorBlind()

export function resultsReducer(results: SaveSearchItemType[], action: SaveSearchAction) {
  switch (action.type) {
    case 'add': {
      return [
        ...results,
        Object.assign({}, action.result, {
          color: colorPalette[results.length%10]
        }),
      ];
    }
    case 'deleted': {
      if (action.idx !== undefined) {
        const idx = results.findIndex((r) => r.responseState.requestId === action.idx)
        return [
          ...results.slice(0, idx),
          ...results.slice(idx + 1),
        ]
      } else {
        return results
      }
    }
    case 'clear': {
      return [];
    }
    case 'toggleMapShow': {
      if (action.idx !== undefined) {
        const idx = results.findIndex((r) => r.responseState.requestId === action.idx)
        return [
          ...results.slice(0, idx),
          Object.assign({}, results[idx], {
            isShown: !results[idx].isShown
          }),
          ...results.slice(idx + 1),
        ]
      } else {
        return results
      }
    }
    case 'select': {
      if (action.idx !== undefined) {
        const idx = results.findIndex((r) => r.responseState.requestId === action.idx)
        return results.map((r, i) => Object.assign({}, r, {
          isSelected: idx === i && !results[idx].isSelected
        }))
      } else {
        return results
      }
    }
    case 'clearSelect': {
      return results.map((r, i) => Object.assign({}, r, {
        isSelected: false
      }));
    }
    case 'deleteItem': {
      if (action.jdx !== undefined) {
        const idx = results.findIndex((r) => r.responseState.requestId === action.jdx?.[0])
        const c = Object.assign({}, results[idx]);
        const jdx = c.responseState.results.findIndex((r) => r.id.raw === action.jdx?.[1])
        if (idx > -1 && jdx > -1) {
          c.responseState.results = [
            ...c.responseState.results.slice(0, jdx),
            ...c.responseState.results.slice(jdx + 1),
          ]
          return [
            ...results.slice(0, idx),
            c,
            ...results.slice(idx + 1),
          ];
        }
      }
      return results
    }
    default: {
      throw Error('Unknown action: ' + action.type);
    }
  }
}

export const ResultsContext = createContext<SaveSearchItemType[] | undefined>(undefined);
export const ResultsDispatchContext = createContext<Dispatch<SaveSearchAction> | undefined>(undefined);
export const IsShowResultContext = createContext<boolean>(true);